window._ = require('lodash');

try {
    window.$ = window.jQuery = require('jquery');
    window.Popper = require('popper.js').default;
} catch (e) {}

require('admin-lte');

window.toastr = require('toastr');

import flatpickr from 'flatpickr';
window.flatpickr = flatpickr;

import Sortable from 'sortablejs/modular/sortable.complete.esm.js';
window.Sortable = Sortable;

window.axios = require('axios');

window.NiceSelect = require('nice-select2/src/js/nice-select2.js');
